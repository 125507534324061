import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import { ButtonComponent } from '../button/button.component';
import { MediaDirectivesModule } from '../../directives/media/media-directives.module';
import { SnackbarService } from '../snackbar/snackbar.service';
import { SnackBarType } from '../snackbar/snackbar-type.enum';

export interface Consents {
  accepted: boolean;
  analytics: boolean;
  social: boolean;
}

@Component({
  templateUrl: './cookie-banner-dialog.component.html',
  styleUrls: ['./cookie-banner-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatDialogModule,
    MediaDirectivesModule,
    FormsModule,
    RouterModule,
    ButtonComponent,
  ],
})
export class CookieBannerDialogComponent implements OnInit {
  protected adjustPreferences = false;
  protected analyticsConsent = true;
  protected socialConsent = true;
  protected showInfoBtn = true;

  protected readonly dialogRef = inject(MatDialogRef<CookieBannerDialogComponent, void>);
  private readonly snackbarService = inject(SnackbarService);

  ngOnInit(): void {
    const consents: Consents = JSON.parse(localStorage.getItem('consents')) || undefined;

    if (consents) {
      this.adjustPreferences = true;
      this.analyticsConsent = consents.analytics === undefined ? true : consents.analytics;
      this.socialConsent = consents.social  === undefined ? true : consents.social;
      this.showInfoBtn = false;
    }
  }

  protected consent(): void {
    localStorage.setItem('consents', JSON.stringify({
      accepted: true,
      analytics: this.analyticsConsent,
      social: this.socialConsent,
    } as Consents));

    this.snackbarService.open('Zapisano preferencje', SnackBarType.SUCCESSFUL, 2000);
    this.dialogRef.close();
  }
}
