<div mat-dialog-content *onlyDesktopTablet>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-container *onlyPortraitLandscape>
  <div mat-dialog-title>
    Zgoda na ciasteczka
  </div>

  <div mat-dialog-content>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-container>

<ng-template #content>
  <p>
    Ta strona korzysta z ciasteczek w celu zapewnienia jej prawidłego działania. Dalsze korzystanie ze strony oznacza
    zgodę na ich użycie.
  </p>

  @if (!adjustPreferences) {
    <div class="actions">
      <app-button text="Akceptuj wszystkie" (click$)="consent()"></app-button>
      <app-button text="Dostosuj preferencje" (click$)="adjustPreferences = !adjustPreferences"></app-button>
    </div>
  }

  @if (adjustPreferences) {
    <p class="preferences-paragraph">Poprawne działanie strony wymaga tzw.
    operacyjnych plików cookies. Zakres zgód na przetwarzanie pozostałych danych możliwy jest do dostosowania:</p>
    <mat-checkbox [(ngModel)]="socialConsent">
      Zgoda na integrację z mediami społecznościowymi
    </mat-checkbox>
    <p class="small">
      Dotyczy przetwarzania danych dostawców usług: Facebooka, Tweettera, dzięki czemu możliwe będzie udostępnianie
      artykułów ze strony w wybranych mediach.
    </p>
    <mat-checkbox [(ngModel)]="analyticsConsent">
      Zgoda na monitorowanie ruchu na stronie
    </mat-checkbox>
    <p class="small">
      Dotyczy przetwarzania danych przez usługodawcę Google Analytics, odwiedzin poszczególnych adresów, treści oraz
      użycia narzędzi udostepnionych na Stronie.
    </p>
    <div class="actions preferences">
      <app-button text="Zapisz preferencje" (click$)="consent()"></app-button>

      @if (showInfoBtn) {
        <app-button text="Dowiedz się więcej" link="./polityka-prywatnosci" (click$)="dialogRef.close()"></app-button>
      }
    </div>
  }
</ng-template>
